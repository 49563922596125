<script setup lang="ts">
import type { Schemas } from "#shopware";
import debounce from "lodash/debounce";
import { formatPrice } from "~/helpers/formatters";
import { getThumbnailUrl } from "~/helpers/mediaHelpers";
import { getExclusiveDeliveryMethodMessage } from "~/helpers/shippingMethodHelpers";

defineProps<{
	readOnly?: boolean;
	items?: Schemas["LineItem"][] | Schemas["OrderLineItem"][] | null;
}>();

const { cartItems, removeItem, changeProductQuantity } = useCart();
const { onRemoveFromCart } = useGtagEvents();

const isUpdatingCart = ref<boolean>(false);

type LineItemType = Schemas["LineItem"] | Schemas["OrderLineItem"];

const isOrderLineItem = (item: LineItemType) => "apiAlias" in item && item.apiAlias === "order_line_item";

const isPromotion = (item: LineItemType) => "type" in item && item.type === "promotion";

const getLineItemThumbnailUrl = (item: LineItemType) => {
	const url = item.cover && getThumbnailUrl(item.cover as Schemas["Media"]);

	return url || ""; // Add fallback url?
};

const updateCartItem = debounce(async (item: LineItemType, quantity: number) => {
	if (isOrderLineItem(item)) return;

	isUpdatingCart.value = true;
	await changeProductQuantity({
		id: item.id,
		quantity
	});
	isUpdatingCart.value = false;
}, 500);

const removeCartItem = async (item: LineItemType) => {
	if (isOrderLineItem(item)) return;

	isUpdatingCart.value = true;
	await removeItem(item as Schemas["LineItem"]);
	try {
		onRemoveFromCart(item as Schemas["LineItem"], item.quantity);
	} catch (error) {
		console.error("Error onRemoveFromCart", error);
	}
	isUpdatingCart.value = false;
};

const getItemTotalPrice = (item: LineItemType) => {
	if (isOrderLineItem(item)) {
		return formatPrice((item as Schemas["OrderLineItem"]).totalPrice);
	}

	// is line-item
	return formatPrice((item as Schemas["LineItem"]).price?.totalPrice);
};
</script>

<template>
	<template v-for="item in items || cartItems" :key="item.id">
		<div
			class="bg-sand py-4 px-4 rounded text-sm flex justify-between gap-2 items-center"
			:class="{
				'pointer-events-none opacity-50': isUpdatingCart
			}"
			v-if="isPromotion(item)"
		>
			<span class="font-medium grow">
				{{ item.label }}
			</span>
			<span class="">
				{{ getItemTotalPrice(item) }}
			</span>

			<button class="p-0 m-0 max-h-min text-gray hover:text-black-light" @click="removeCartItem(item)">
				<SvgIcon name="trash" />
			</button>
		</div>

		<KippieCartItem
			v-if="!isPromotion(item)"
			:title="item.label || ''"
			:price="getItemTotalPrice(item)"
			:quantity="item.quantity"
			:stackable="readOnly ? false : item.stackable"
			:removable="readOnly ? false : !!item.removable"
			:class="{
				'pointer-events-none opacity-50': isUpdatingCart
			}"
			@update="updateCartItem(item, $event)"
			@remove="removeCartItem(item)"
		>
			<template #image>
				<img :src="getLineItemThumbnailUrl(item)" width="50" />
			</template>

			<span class="text-xs text-gray flex flex-col">
				<span>
					{{
						// @ts-ignore
						// @TODO: Add this customField in storeApiTypes overrides Martijn
						item.payload?.productCustomFields?.kippie_product_fields_first_label
					}}
				</span>
				<span>
					{{
						// @ts-ignore
						// @TODO: Add this customField in storeApiTypes overrides Martijn
						item.payload?.productCustomFields?.kippie_product_fields_second_label
					}}
				</span>
			</span>

			<div
				class="rounded-full text-xs bg-gray-200 text-gray px-2 py-0.5 font-medium self-start mt-4"
				v-if="getExclusiveDeliveryMethodMessage(item.payload)"
			>
				{{ getExclusiveDeliveryMethodMessage(item.payload) }}
			</div>
		</KippieCartItem>

		<Divider class="last-of-type:hidden" />
	</template>
</template>
